import * as React from "react"

import profile2 from "./../images/avatars/profile-picture-2.jpg";
import beats from "./../images/components/beats.png";

class CTA extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {

        return (
            <section className="py-5 py-lg-7 d-flex align-items-center">
                <div className="container z-2">
                    <div className="row align-items-center mb-md-5">
                        <div className="col-12">
                            <div className="mb-4">
                                <h2 className="display-3 fw-extrabold align-items-center me-0">Sign up to Glass UI</h2>
                            </div>
                            <p className="lead fw-normal mb-3 mb-5 pe-xl-12">A free and open-source <span className="fw-bold text-highlight">CSS UI library</span> based on the <span className="fw-bold text-highlight">glassmorphism design specifications</span> that will help you quickly design and build beautiful websites and applications.</p>
                            <div className="mb-sm-8 fmxw-600">
                                <label for="email" className="d-block fw-bold signup-label text-highlight mb-3">Get notified
                                    when we launch</label>
                                <div id="revue-embed" className="mb-5">
                                    <form action="https://www.getrevue.co/profile/glass-ui/add_subscriber" method="post" id="revue-form" name="revue-form" target="_blank">
                                        <div className="d-flex mb-2">
                                            <div className="revue-form-group me-3 w-100">
                                                <label for="member_email" className="d-none">Email address</label>
                                                <input className="revue-form-field form-control form-control-lg" placeholder="Your email address..." type="email" name="member[email]" id="member_email" required />
                                            </div>
                                            <div className="revue-form-actions">
                                                <input className="btn btn-lg btn-primary rounded-1" type="submit" value="Subscribe" name="member[subscribe]" id="member_submit" />
                                            </div>
                                        </div>
                                        <div className="revue-form-footer">By subscribing, you agree with Revue’s <a target="_blank" rel="nofollow" href="https://www.getrevue.co/terms">Terms of Service</a> and <a target="_blank" rel="nofollow" href="https://www.getrevue.co/privacy">Privacy Policy</a>.</div>
                                    </form>
                                </div>
                            </div>
                            <div className="position-relative">
                                <div className="presentation-shape d-flex rounded w-100"></div>
                                <div className="presentation-shape-2 d-flex rounded pattern rotate w-100"></div>

                                <div className="card blur-15 shadow component-presentation-1 d-none d-md-block">
                                    <div className="card-header">
                                        <img src={beats} alt="Beats speakers" />
                                    </div>
                                    <div className="card-body border-0">
                                        <h3 href="#" className="fs-5">Beats Pill</h3>
                                        <h4 className="fs-6 fw-normal text-muted mt-2">Black Beats Pill + Portable Speaker</h4>
                                        <div className="d-flex align-items-center mt-3">
                                            <svg className="icon icon-xs text-warning" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path></svg>
                                            <svg className="icon icon-xs text-warning" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path></svg>
                                            <svg className="icon icon-xs text-warning" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path></svg>
                                            <svg className="icon icon-xs text-warning" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path></svg>
                                            <svg className="icon icon-xs text-warning" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path></svg>
                                            <span className="badge bg-primary py-1 ms-2">4.7</span>
                                        </div>
                                    </div>
                                    <div className="card-footer p-4">
                                        <div className="d-flex align-items-center">
                                            <span className="h5 mb-0 line-through me-2">$299.00 </span><span className="h6 mb-0 text-danger">$199.00</span>
                                            <div className="ms-auto">
                                                <button className="btn btn-icon-only btn-primary px-1" type="button" aria-label="add to cart button" title="add to cart button">
                                                    <svg className="icon icon-xs" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M3 1a1 1 0 000 2h1.22l.305 1.222a.997.997 0 00.01.042l1.358 5.43-.893.892C3.74 11.846 4.632 14 6.414 14H15a1 1 0 000-2H6.414l1-1H14a1 1 0 00.894-.553l3-6A1 1 0 0017 3H6.28l-.31-1.243A1 1 0 005 1H3zM16 16.5a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0zM6.5 18a1.5 1.5 0 100-3 1.5 1.5 0 000 3z"></path></svg>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="card border blur-15 shadow component-presentation-2">
                                    <div className="card-body">
                                        <div className="mb-4 mb-sm-0 d-md-none d-lg-block">
                                            <img className="image rounded-circle mb-2" alt="Chris Wood" src={profile2} />
                                        </div>
                                        <h3 className="h5 mb-0">Chadwick Harrison</h3>
                                        <span className="text-muted">Senior Designer</span>
                                        <div className="d-flex my-3">
                                            <div className="d-flex flex-column me-4">
                                                <span className="h6 fw-normal text-muted">Articles</span>
                                                <span className="h5 fw-bold">38</span>
                                            </div>
                                            <div className="d-flex flex-column me-4">
                                                <span className="h6 fw-normal text-muted">Followers</span>
                                                <span className="h5 fw-bold">100k</span>
                                            </div>
                                            <div className="d-flex flex-column">
                                                <span className="h6 fw-normal text-muted">Posts</span>
                                                <span className="h5 fw-bold">1578</span>
                                            </div>
                                        </div>
                                        <div className="d-grid">
                                            <button className="btn btn-sm btn-primary d-inline-flex align-items-center justify-content-center">
                                                <svg className="icon icon-sm me-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M8 9a3 3 0 100-6 3 3 0 000 6zM8 11a6 6 0 016 6H2a6 6 0 016-6zM16 7a1 1 0 10-2 0v1h-1a1 1 0 100 2h1v1a1 1 0 102 0v-1h1a1 1 0 100-2h-1V7z"></path></svg>
                                                Follow
                                            </button>
                                        </div>
                                    </div>
                                </div>

                                <div className="card blur-15 shadow p-4 mb-4 component-presentation-3 d-none d-sm-block">
                                    <div className="card-header text-center pt-0">
                                        <span className="display-1 fw-bolder">
                                            <span className="align-top fw-bold fs-3">$</span>19
                                        </span>
                                        <span className="fw-bold text-muted small">/ month</span>
                                    </div>
                                    <div className="card-body px-0 pb-0">
                                        <p>Faster sites deliver better business results for your clients.</p>
                                        <ul className="list-unstyled mb-4">
                                            <li className="list-item pb-2"><span className="h6">Full Support</span> No</li>
                                            <li className="list-item pb-2"><span className="h6">Storage</span> 50 GB</li>
                                            <li className="list-item"><span className="h6">Monthly Visitors</span> 400k</li>
                                        </ul>
                                        <div className="d-grid">
                                            <a href="#" className="btn btn-primary d-inline-flex align-items-center justify-content-center">
                                                <svg className="icon icon-sm me-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M3 1a1 1 0 000 2h1.22l.305 1.222a.997.997 0 00.01.042l1.358 5.43-.893.892C3.74 11.846 4.632 14 6.414 14H15a1 1 0 000-2H6.414l1-1H14a1 1 0 00.894-.553l3-6A1 1 0 0017 3H6.28l-.31-1.243A1 1 0 005 1H3zM16 16.5a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0zM6.5 18a1.5 1.5 0 100-3 1.5 1.5 0 000 3z"></path></svg>
                                                Add to Cart
                                            </a>
                                        </div>
                                    </div>
                                </div>


                                <div className="card blur-15 shadow component-presentation-4 d-none d-sm-block">
                                    <div className="card-body d-block d-md-flex align-items-center">
                                        <div className="icon-shape icon-shape-primary rounded-circle me-3 mb-4 mb-md-0">
                                            <svg fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
                                        </div>
                                        <div>
                                            <span className="d-block h6 fw-normal text-muted">Global Budget</span>
                                            <h5 className="h3 fw-extrabold mb-1">$25,370</h5>
                                            <div className="small mt-2">
                                                <span className="text-success fw-bold">18.2%</span> higher vs previous month
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )

    }
}

export default CTA
