import * as React from "react";
import ColorPicker from "../components/color-picker";
import Select from 'react-select';
import RangeSlider from 'react-bootstrap-range-slider';

class Filters extends React.Component {

    handleBackgroundImageURLClick = (event) => {
        console.log(event);
        event.target.select();
    }

    backgroundOptions = (backgroundType) => {
        switch (backgroundType) {
            case 'solid':
                return (
                    <div className="col-12 col-lg-6 col-xl-3 mb-3 mb-xl-0">
                        <label htmlFor="color" className="form-label small">Background color</label>
                        <div className="filter-item">
                            <ColorPicker color={this.props.colorBackground} handleColorChange={this.props.handleColorBackgroundChange} />
                        </div>
                    </div>
                );
            case 'gradient':
                return (
                    <div className="col-12 col-lg-6 col-xl-3 mb-3 mb-xl-0">
                        <label htmlFor="color" className="form-label small">Background colors</label>
                        <div className="filter-item">
                            <div className="w-100 d-flex justify-content-between">
                                <ColorPicker color={this.props.colorBackground} handleColorChange={this.props.handleColorBackgroundChange} />
                                <ColorPicker color={this.props.colorBackgroundSecondary} handleColorChange={this.props.handleColorBackgroundSecondaryChange} />
                                <ColorPicker color={this.props.colorBackgroundTertiary} handleColorChange={this.props.handleColorBackgroundTertiaryChange} />
                            </div>
                        </div>
                    </div>
                );
            case 'background_image':
                return (
                    <div className="col-12 col-lg-6 col-xl-3 mb-3 mb-xl-0">
                        <label htmlFor="image" className="form-label small">Background image URL</label>
                        <div className="filter-item">
                            <input id="image" className="form-control background-image-input w-100" type="text" placeholder="Image URL" value={this.props.backgroundImageURL} onChange={this.props.handleBackgroundImageURLChange} onClick={this.handleBackgroundImageURLClick} />
                        </div>
                    </div>

                )
        }
    }

    render() {

        const backgroundTypes = [
            { value: 'solid', label: 'Solid' },
            { value: 'gradient', label: 'Mesh Gradient' },
            { value: 'background_image', label: 'Image' },
        ]

        return (
            <div className="option-card shadow-lg rounded px-4 py-3 mb-4">
                <div className="row">


                    {this.backgroundOptions(this.props.backgroundType)}

                    <div className="col-12 col-lg-6 col-xl-2 mb-3 mb-xl-0">
                        <label htmlFor="backgroundType" className="form-label small">Background type</label>
                        <div className="filter-item">
                            <Select
                                defaultValue={backgroundTypes[2]}
                                options={backgroundTypes}
                                className="d-block w-100"
                                onChange={this.props.handleBackgroundTypeChange}
                                styles={{
                                    option: (provided, state) => ({
                                        ...provided,
                                        padding: 7,
                                        fontFamily: 'Inter, sans-serif !important',
                                        fontSize: 14,
                                        color: this.props.darkMode ? '#fff' : '#4B5663',
                                        backgroundColor: 'transparent',
                                        backgroundColor: state.isSelected ? '#2ea44f' : 'transparent',
                                        color: state.isSelected ? '#fff' : '4B5663',
                                        "&:hover": {
                                            backgroundColor: "#2ea44f",
                                            color: '#fff'
                                        }
                                    }),
                                    singleValue: (provided, state) => ({
                                        ...provided,
                                        opacity: state.isDisabled ? 0.5 : 1,
                                        transition: 'opacity 300ms',
                                        color: this.props.darkMode ? '#fff' : '#4B5663',
                                        backgroundColor: this.props.darkMode ? 'rgba(17, 25, 40, 0.6)' : 'rgba(255, 255, 255, 0.6)'
                                    }),
                                    control: styles => ({
                                        ...styles,
                                        color: this.props.darkMode ? '#fff' : '#4B5663',
                                        border: this.props.darkMode ? '1px solid #4B5663' : '1px solid #E5E7EB',
                                        backdropFilter: 'blur(8px)',
                                        backgroundColor: this.props.darkMode ? 'rgba(17, 25, 40, 0.6)' : 'rgba(255, 255, 255, 0.6)',
                                        fontSize: 14,
                                        "&:hover": {
                                            border: "1px solid #2ea44f"
                                        }
                                    }),
                                    menu: styles => ({
                                        ...styles,
                                        color: this.props.darkMode ? '#fff' : '#4B5663',
                                        backdropFilter: 'blur(8px)',
                                        backgroundColor: this.props.darkMode ? 'rgba(17, 25, 40, 0.6)' : 'rgba(255, 255, 255, 0.6)'
                                    }),
                                    menuList: styles => ({
                                        ...styles,
                                        color: this.props.darkMode ? '#fff' : '#4B5663',
                                        borderRadius: '.3rem',
                                    }),
                                }}
                                theme={theme => ({
                                    ...theme,
                                    colors: {
                                        ...theme.colors,
                                        primary: '#2ea44f'
                                    }
                                })}
                            />
                        </div>
                    </div>
                    <div className="col-12 col-lg-6 col-xl-1 mb-3 mb-xl-0">
                        <label htmlFor="color" className="form-label small">Card color</label>
                        <div className="filter-item">
                            <ColorPicker
                                color={this.props.color}
                                handleColorChange={this.props.handleColorChange} />
                        </div>
                    </div>
                    <div className="col-12 col-lg-6 col-xl-2 mb-3 mb-xl-0">
                        <label htmlFor="blur" className="form-label small">Blur value</label>
                        <div className="filter-item">
                            <RangeSlider
                                id="blur"
                                min={0}
                                max={25}
                                className="form-range range-slider--gray-500"
                                value={this.props.blurValue}
                                onChange={this.props.handleBlurChange}
                                tooltipLabel={currentValue => `${currentValue}px`}
                                tooltipPlacement="top"
                                tooltip='on'
                            />
                        </div>
                    </div>
                    <div className="col-12 col-lg-6 col-xl-2 mb-3 mb-xl-0">
                        <label htmlFor="opacity" className="form-label small">Opacity</label>
                        <div className="filter-item">
                            <RangeSlider
                                id="opacity"
                                min={0}
                                max={100}
                                className="form-range range-slider--gray-500"
                                value={this.props.transparencyValue}
                                onChange={this.props.handleTransparencyChange}
                                tooltipLabel={currentValue => `${currentValue}%`}
                                tooltipPlacement="top"
                                tooltip='on'
                            />
                        </div>
                    </div>
                    <div className="col-12 col-lg-6 col-xl-2 mb-3 mb-xl-0">
                        <label htmlFor="saturation" className="form-label small">Saturation</label>
                        <div className="filter-item">
                            <RangeSlider
                                id="saturation"
                                min={0}
                                max={200}
                                className="form-range range-slider--gray-500"
                                value={this.props.saturationValue}
                                onChange={this.props.handleSaturationChange}
                                tooltipLabel={currentValue => `${currentValue}%`}
                                tooltipPlacement="top"
                                tooltip='on'
                            />
                        </div>
                    </div>

                </div>
            </div>
        )

    }
}

export default Filters
