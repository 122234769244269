'use strict'

import React from 'react'
import reactCSS from 'reactcss'
import { SketchPicker } from 'react-color'

class ColorPicker extends React.Component {
    state = {
        displayColorPicker: false,
    };

    handleClick = () => {
        this.setState({ displayColorPicker: !this.state.displayColorPicker })
    };

    handleClose = () => {
        this.setState({ displayColorPicker: false })
    };

    render() {

        const styles = reactCSS({
            'default': {
                color: {
                    background: `rgba(${this.props.color.rgb.r}, ${this.props.color.rgb.g}, ${this.props.color.rgb.b}, ${this.props.color.rgb.a})`,
                },
                popover: {
                    position: 'absolute',
                    zIndex: '2',
                },
                cover: {
                    position: 'fixed',
                    top: '0px',
                    right: '0px',
                    bottom: '0px',
                    left: '0px',
                },
            },
        });

        return (
            <div className="w-100 d-inline-block">
                <div className="swatch" onClick={this.handleClick}>
                    <div className="swatch-color" style={styles.color} />
                </div>
                {this.state.displayColorPicker ? <div style={styles.popover}>
                    <div style={styles.cover} onClick={this.handleClose} />
                    <SketchPicker color={this.props.color} onChange={this.props.handleColorChange} id="color" />
                </div> : null}

            </div>
        )
    }
}

export default ColorPicker