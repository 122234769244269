import * as React from "react"
import { Helmet } from "react-helmet";

import ogImage from "./../images/og-image.png";

import Navbar from './../sections/navbar.js';
import Hero from './../sections/hero.js';
import Generator from './../sections/generator.js';
import FAQ from '../sections/faq.js';
import CTA from './../sections/cta.js';
import Footer from './../sections/footer.js';

class IndexPage extends React.Component {

  render() {

    return (
      <div id="overview">
        <Helmet>
          <meta charSet="utf-8" />
          <title>Glassmorphism CSS Generator - Glass UI</title>
          <link rel="canonical" href="https://ui.glass/generator/" />
          <meta name="description" content="Generate CSS and HTML components using the glassmorphism design specifications based on the Glass UI library." />

          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:site" content="@zoltanszogyenyi" />
          <meta name="twitter:title" content="Glassmorphism CSS Generator - Glass UI" />
          <meta name="twitter:description" content="Generate CSS and HTML components using the glassmorphism design specifications based on the Glass UI library." />
          <meta name="twitter:image" content={'https://ui.glass' + ogImage} />
          <meta name="twitter:creator" content="@zoltanszogyenyi" />

          <meta property="og:url" content="https://ui.glass/generator/" />
          <meta property="og:type" content="article" />
          <meta property="og:title" content="Glassmorphism CSS Generator - Glass UI" />
          <meta property="og:description" content="Generate CSS and HTML components using the glassmorphism design specifications based on the Glass UI library." />
          <meta property="og:image" content={'https://ui.glass' + ogImage} />

          {/* DON'T DELETE (search console verification) */}

        </Helmet>
        <Navbar></Navbar>
        <main>
          <Hero></Hero>
          <Generator></Generator>
          <FAQ></FAQ>
          <CTA></CTA>
        </main>
        <Footer></Footer>
        <a className="ph-badge" href="https://www.producthunt.com/posts/glass-ui-generator?utm_source=badge-top-post-badge&utm_medium=badge&utm_souce=badge-glass-ui-generator" target="_blank"><img src="https://api.producthunt.com/widgets/embed-image/v1/top-post-badge.svg?post_id=303617&theme=dark&period=daily" alt="Glass UI Generator - CSS generator for glassmorphism | Product Hunt" style={{ width: 250, height: 54 }} width="250" height="54" /></a>
      </div>
    )
  }
}
export default IndexPage