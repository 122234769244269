import * as React from "react"
import Select from 'react-select'

import profile1 from "./../images/avatars/profile-picture-1.jpg";
import profile2 from "./../images/avatars/profile-picture-2.jpg";
import profile3 from "./../images/avatars/profile-picture-3.jpg";
import profile4 from "./../images/avatars/profile-picture-4.jpg";
import beats from "./../images/components/beats.png";

class ElementOptions extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            elementType: 'members_card'
        }

        this.handleElementTypeChange = this.handleElementTypeChange.bind(this);
    }

    handleElementTypeChange = (elementType) => {
        this.setState({ elementType: elementType.value })
    };

    render() {

        const componentTypeOptions = [
            { value: 'members_card', label: 'Members card' },
            { value: 'profile_card', label: 'Profile card' },
            { value: 'pricing_card', label: 'Pricing card' },
            { value: 'product_card', label: 'Product card' },
            { value: 'signin_card', label: 'Sign in card' }
        ]

        var backgroundStyles = this.props.getBackgroundStyles(this.props);

        return (
            <div className="option-card shadow-lg rounded px-2 px-sm-3 px-lg-4 py-4">
                <div className="row mb-4">
                    <div className="col-6 col-lg-4">
                        <Select
                            defaultValue={componentTypeOptions[0]}
                            options={componentTypeOptions}
                            onChange={this.handleElementTypeChange}
                            styles={{
                                option: (provided, state) => ({
                                    ...provided,
                                    padding: 10,
                                    fontFamily: 'Inter, sans-serif !important',
                                    fontSize: 14,
                                    color: this.props.darkMode ? '#fff' : '#4B5663',
                                    backgroundColor: 'transparent',
                                    backgroundColor: state.isSelected ? '#2ea44f' : 'transparent',
                                    color: state.isSelected ? '#fff' : '4B5663',
                                    "&:hover": {
                                        backgroundColor: "#2ea44f",
                                        color: '#fff'
                                    }
                                }),
                                singleValue: (provided, state) => ({
                                    ...provided,
                                    opacity: state.isDisabled ? 0.5 : 1,
                                    transition: 'opacity 300ms',
                                    color: this.props.darkMode ? '#fff' : '#4B5663',
                                    backgroundColor: this.props.darkMode ? 'rgba(17, 25, 40, 0.6)' : 'rgba(255, 255, 255, 0.6)'
                                }),
                                control: styles => ({
                                    ...styles,
                                    color: this.props.darkMode ? '#fff' : '#4B5663',
                                    border: this.props.darkMode ? '1px solid #4B5663' : '1px solid #E5E7EB',
                                    backdropFilter: 'blur(8px)',
                                    backgroundColor: this.props.darkMode ? 'rgba(17, 25, 40, 0.6)' : 'rgba(255, 255, 255, 0.6)',
                                    fontSize: 14,
                                    "&:hover": {
                                        border: "1px solid #2ea44f"
                                    }
                                }),
                                menu: styles => ({
                                    ...styles,
                                    color: this.props.darkMode ? '#fff' : '#4B5663',
                                    backdropFilter: 'blur(8px)',
                                    backgroundColor: this.props.darkMode ? 'rgba(17, 25, 40, 0.6)' : 'rgba(255, 255, 255, 0.6)',
                                }),
                                menuList: styles => ({
                                    ...styles,
                                    color: this.props.darkMode ? '#fff' : '#4B5663',
                                    borderRadius: '.3rem',
                                }),
                            }}
                            theme={theme => ({
                                ...theme,
                                colors: {
                                    ...theme.colors,
                                    primary: '#2ea44f'
                                }
                            })}
                        />
                    </div>
                    <div className="col-6 col-lg-8 ms-auto">
                        <div className="toggle theme-switch d-flex align-items-center ps-0 justify-content-end">
                            <svg className={this.props.darkMode ? 'icon-toggle' : 'icon-toggle active'} fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 3v1m0 16v1m9-9h-1M4 12H3m15.364 6.364l-.707-.707M6.343 6.343l-.707-.707m12.728 0l-.707.707M6.343 17.657l-.707.707M16 12a4 4 0 11-8 0 4 4 0 018 0z"></path>
                            </svg>
                            <input className="toggle-input" type="checkbox" id="darkMode" checked={this.props.darkMode} onChange={this.props.handleDarkModeChange}/>
                            <label className="mx-2" title="toggle for dark/light mode" htmlFor="darkMode"></label>
                            <svg className={this.props.darkMode ? 'icon-toggle active' : 'icon-toggle'} fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M20.354 15.354A9 9 0 018.646 3.646 9.003 9.003 0 0012 21a9.003 9.003 0 008.354-5.646z">
                                </path>
                            </svg>
                        </div>
                    </div>
                    {/* <div className="col-6 col-lg-8">
                        <div className="form-check form-switch homepage-switch d-flex align-items-center ps-0 justify-content-end">
                            <svg className="sun" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 3v1m0 16v1m9-9h-1M4 12H3m15.364 6.364l-.707-.707M6.343 6.343l-.707-.707m12.728 0l-.707.707M6.343 17.657l-.707.707M16 12a4 4 0 11-8 0 4 4 0 018 0z"></path>
                            </svg>
                            <div className="mx-2">
                                <input className="form-check-input check-lg alternate-check" type="checkbox" id="darkMode" checked={this.props.darkMode} onChange={this.props.handleDarkModeChange} />
                            </div>
                            <svg className="moon" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M20.354 15.354A9 9 0 018.646 3.646 9.003 9.003 0 0012 21a9.003 9.003 0 008.354-5.646z">
                                </path>
                            </svg>
                        </div>
                    </div> */}
                </div>
                <div className="showcase-card rounded" style={backgroundStyles}>

                    {this.state.elementType === 'members_card' ?
                        <div className="card" style={{ backdropFilter: `blur(${this.props.blur}px) saturate(${this.props.saturation}%)`, background: `rgba(${this.props.color.rgb.r}, ${this.props.color.rgb.g}, ${this.props.color.rgb.b}, ${this.props.transparency / 100})`, borderRadius: '12px' }}>
                            <div className="card-header px-3 px-sm-4 d-flex justify-content-between">
                                <h2 className="fs-5 mb-0">Team members</h2>
                                <a href="#" className="d-inline-flex align-items-center">
                                    <svg className="icon icon-xs me-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M10 12a2 2 0 100-4 2 2 0 000 4z"></path>
                                        <path fillRule="evenodd" d="M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z" clipRule="evenodd"></path>
                                    </svg>
                                    See all
                                </a>
                            </div>
                            <div className="card-body px-3 px-sm-4 py-0">
                                <ul className="list-group list-group-flush">
                                    <li className="list-group-item bg-transparent border-bottom py-3 px-0">
                                        <div className="row align-items-center">
                                            <div className="col-auto">
                                                <a href="#" className="user-avatar rounded-circle bordered"><img className="p-1 rounded-circle" alt="Chris Wood" src={profile1} /></a>
                                            </div>
                                            <div className="col-auto px-0">
                                                <h3 className="fs-6 mb-0">Chris Wood</h3>
                                                <div className="d-flex align-items-center">
                                                    <div className="dot bg-success rounded-circle me-2"></div>
                                                    <span className="small text-muted">Online</span>
                                                </div>
                                            </div>
                                            <div className="col text-end">
                                                <a href="#" className="btn btn-sm btn-gray-600 border-gray-500 d-inline-flex align-items-center">
                                                    <svg className="icon icon-xxs me-1" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-6 9l2 2 4-4"></path>
                                                    </svg>
                                                    Invite
                                                </a>
                                            </div>
                                        </div>
                                    </li>
                                    <li className="list-group-item bg-transparent border-bottom py-3 px-0">
                                        <div className="row align-items-center">
                                            <div className="col-auto">
                                                <a href="#" className="user-avatar rounded-circle bordered"><img className="p-1 rounded-circle" alt="Image placeholder" src={profile2} /></a>
                                            </div>
                                            <div className="col-auto px-0">
                                                <h3 className="fs-6 mb-0">Jose Leos</h3>
                                                <div className="d-flex align-items-center">
                                                    <div className="dot bg-warning rounded-circle me-2"></div>
                                                    <span className="small text-muted">Busy</span>
                                                </div>
                                            </div>
                                            <div className="col text-end">
                                                <a href="#" className="btn btn-sm btn-primary d-inline-flex align-items-center">
                                                    <svg className="icon icon-xxs me-1" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M8 10h.01M12 10h.01M16 10h.01M9 16H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-5l-5 5v-5z"></path>
                                                    </svg>
                                                    Chat
                                                </a>
                                            </div>
                                        </div>
                                    </li>
                                    <li className="list-group-item bg-transparent border-bottom py-3 px-0">
                                        <div className="row align-items-center">
                                            <div className="col-auto">
                                                <a href="#" className="user-avatar rounded-circle bordered"><img className="p-1 rounded-circle" alt="Image placeholder" src={profile3} /></a>
                                            </div>
                                            <div className="col-auto px-0">
                                                <h3 className="fs-6 mb-0">Jeny Green</h3>
                                                <div className="d-flex align-items-center">
                                                    <div className="dot bg-danger rounded-circle me-2"></div>
                                                    <span className="small text-muted">Offline</span>
                                                </div>
                                            </div>
                                            <div className="col text-end">
                                                <a href="#" className="btn btn-sm btn-primary d-inline-flex align-items-center">
                                                    <svg className="icon icon-xxs me-1" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M8 10h.01M12 10h.01M16 10h.01M9 16H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-5l-5 5v-5z"></path>
                                                    </svg>
                                                    Chat
                                                </a>
                                            </div>
                                        </div>
                                    </li>
                                    <li className="list-group-item bg-transparent py-3 px-0">
                                        <div className="row align-items-center">
                                            <div className="col-auto">
                                                <a href="#" className="user-avatar rounded-circle bordered"><img className="p-1 rounded-circle" alt="Image placeholder" src={profile4} /></a>
                                            </div>
                                            <div className="col-auto px-0">
                                                <h3 className="fs-6 mb-0">Neil Sims</h3>
                                                <div className="d-flex align-items-center">
                                                    <div className="dot bg-success rounded-circle me-2"></div>
                                                    <span className="small text-muted">Online</span>
                                                </div>
                                            </div>
                                            <div className="col text-end">
                                                <a href="#" className="btn btn-sm btn-primary d-inline-flex align-items-center">
                                                    <svg className="icon icon-xxs me-1" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M8 10h.01M12 10h.01M16 10h.01M9 16H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-5l-5 5v-5z"></path>
                                                    </svg>
                                                    Chat
                                                </a>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div> : ''}

                    {this.state.elementType === 'profile_card' ? (
                        <div className="card text-center p-4" style={{ backdropFilter: `blur(${this.props.blur}px) saturate(${this.props.saturation}%)`, background: `rgba(${this.props.color.rgb.r}, ${this.props.color.rgb.g}, ${this.props.color.rgb.b}, ${this.props.transparency / 100})`, borderRadius: '12px' }}>
                            <div className="card-header border-0 pb-0">
                                <div className="image-lg mx-auto">
                                    <img src={profile3} className="rounded-circle border border-1 p-3" alt="Joseph Portrait" />
                                </div>
                            </div>
                            <div className="card-body">
                                <h3 className="h5 card-title mb-3">Charles McBrayer</h3>
                                <a className="btn btn-sm btn-primary d-inline-flex align-items-center mb-3" href="#">
                                    <svg className="icon icon-xs me-1" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M8 9a3 3 0 100-6 3 3 0 000 6zM8 11a6 6 0 016 6H2a6 6 0 016-6zM16 7a1 1 0 10-2 0v1h-1a1 1 0 100 2h1v1a1 1 0 102 0v-1h1a1 1 0 100-2h-1V7z"></path></svg>
                                    Follow
                                </a>
                                <p className="card-text text-muted">Some quick example text to build on the card title and make up the bulk of the.</p>
                            </div>
                        </div>
                    ) : ''}

                    {this.state.elementType === 'pricing_card' ? (
                        <div className="card p-4" style={{ backdropFilter: `blur(${this.props.blur}px) saturate(${this.props.saturation}%)`, background: `rgba(${this.props.color.rgb.r}, ${this.props.color.rgb.g}, ${this.props.color.rgb.b}, ${this.props.transparency / 100})`, borderRadius: '12px' }}>
                            <div className="card-header bg-white-25 text-center">
                                <span className="d-block">
                                    <span className="display-1 fw-bolder">
                                        <span className="align-top fw-bold fs-3">$</span>19
                                    </span>
                                    <span className="fw-normal text-muted small">/ month</span>
                                </span>
                            </div>
                            <div className="card-body">
                                <p className="text-muted">Best for higher volume businesses that need additional features and power.</p>
                                <ul className="list-unstyled text-muted mb-4">
                                    <li className="list-item pb-2"><span className="fw-bolder">Full Support</span> No</li>
                                    <li className="list-item pb-2"><span className="fw-bolder">Storage</span> 50 GB</li>
                                    <li className="list-item"><span className="fw-bolder">Monthly Visitors</span> 400k</li>
                                </ul>
                                <div className="d-grid">
                                    <a href="#" className="btn btn-primary d-inline-flex align-items-center justify-content-center">
                                        Buy now
                                    </a>
                                </div>
                            </div>
                        </div>
                    ) : ''}

                    {this.state.elementType === 'product_card' ? (
                        <div className="card" style={{ backdropFilter: `blur(${this.props.blur}px) saturate(${this.props.saturation}%)`, background: `rgba(${this.props.color.rgb.r}, ${this.props.color.rgb.g}, ${this.props.color.rgb.b}, ${this.props.transparency / 100})`, borderRadius: '12px' }}>
                            <div className="card-header">
                                <img src={beats} alt="Beats speakers" />
                            </div>
                            <div className="card-body border-0">
                                <h3 href="#" className="fs-5">Beats Pill</h3>
                                <h4 className="fs-6 fw-normal text-muted mt-2">Black Beats Pill + Portable Speaker</h4>
                                <div className="d-flex align-items-center mt-3">
                                    <svg className="icon icon-xs text-warning" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path></svg>
                                    <svg className="icon icon-xs text-warning" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path></svg>
                                    <svg className="icon icon-xs text-warning" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path></svg>
                                    <svg className="icon icon-xs text-warning" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path></svg>
                                    <svg className="icon icon-xs text-warning" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path></svg>
                                    <span className="badge bg-primary py-1 ms-2">4.7</span>
                                </div>
                            </div>
                            <div className="card-footer p-4">
                                <div className="d-flex align-items-center">
                                    <span className="h5 mb-0 line-through me-2">$299.00 </span><span className="h6 mb-0 text-danger">$199.00</span>
                                    <div className="ms-auto">
                                        <a href="#" className="btn btn-primary d-inline-flex align-items-center justify-content-center">
                                            <svg className="icon icon-xs me-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M3 1a1 1 0 000 2h1.22l.305 1.222a.997.997 0 00.01.042l1.358 5.43-.893.892C3.74 11.846 4.632 14 6.414 14H15a1 1 0 000-2H6.414l1-1H14a1 1 0 00.894-.553l3-6A1 1 0 0017 3H6.28l-.31-1.243A1 1 0 005 1H3zM16 16.5a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0zM6.5 18a1.5 1.5 0 100-3 1.5 1.5 0 000 3z"></path></svg>
                                            Add to cart
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : ''}

                    {this.state.elementType === 'signin_card' ? (
                    <div className="card p-lg-3" style={{ backdropFilter: `blur(${this.props.blur}px) saturate(${this.props.saturation}%)`, background: `rgba(${this.props.color.rgb.r}, ${this.props.color.rgb.g}, ${this.props.color.rgb.b}, ${this.props.transparency / 100})`, borderRadius: '12px' }}>
                        <div className="card-header bg-transparent border-0 pb-0 mb-3">
                            <h2 className="h4">Sign in to our platform</h2>
                            <span className="text-muted">Login here using your username and password</span>   
                        </div>
                        <div className="card-body">
                            <form action="#">
                                <div className="mb-4">
                                    <label className="mb-2" for="exampleInputEmailCard1">Your Email</label>
                                    <div className="input-group">
                                        <span className="input-group-text " id="basic-addon1">
                                            <svg className="icon icon-xs" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z"></path><path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z"></path></svg>
                                        </span>
                                        <input type="email" className="form-control" placeholder="example@company.com" id="exampleInputEmailCard1" aria-describedby="exampleInputEmailCard1" required/>
                                    </div>  
                                </div>
                                <div className="mb-4">
                                    <label className="mb-2" for="exampleInputPasswordCard1">Your Password</label>
                                    <div className="input-group">
                                        <span className="input-group-text" id="basic-addon2">
                                            <svg className="icon icon-xs" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z" clip-rule="evenodd"></path></svg>
                                        </span>
                                        <input type="password" className="form-control" placeholder="Password" id="exampleInputPasswordCard1" aria-describedby="exampleInputPasswordCard1" required/>
                                    </div>  
                                </div>
                                <div className="d-block d-sm-flex justify-content-between align-items-center mb-4">
                                    <div className="form-check mb-0">
                                        <input className="form-check-input" type="checkbox" value="" id="defaultCheck5" />
                                        <label className="form-check-label mb-0" for="defaultCheck5">
                                            Remember me
                                        </label>
                                    </div>
                                    <div><a href="#" className="small text-right">Lost password?</a></div>
                                </div>
                                <div className="d-grid">
                                    <button type="submit" className="btn btn-primary">Sign in</button>
                                </div>
                            </form>
                            <div className="d-block d-sm-flex align-items-center mt-4">
                                <span className="fw-normal small">
                                    Not registered?
                                    <a href="#" className="fw-bold ms-2">Create account</a>
                                </span>
                            </div>
                        </div>
                    </div>
                    ) : ''}

                </div>
            </div>
        );
    }
}
export default ElementOptions
