import * as React from "react"
// import CarbonAds from "react-carbon-ads"

// markup
const Hero = () => {
    return (
        <section className="pt-4 pt-lg-5">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-12 col-lg-7">
                        <h1 className="display-3 fw-extrabold lh-sm">Glassmorphism CSS Generator</h1>
                        <p className="lead fw-normal mb-3">Get started with this free CSS generator based on the <span className="fw-bold text-highlight">glassmorphism</span> design specifications to quickly design and customize the style properties.</p>
                        <p className="fw-normal">Design based on <a href="https://ui.glass" className="hover-text-underline">ui.glass</a> and built by <a href="https://flowbite.com?ref=glass-ui-generator" target="_blank" className="hover-text-underline">Flowbite</a>.</p>
                    </div>
                    <div className="col-12 col-lg-5 d-flex justify-content-lg-end mt-2 mt-lg-0">
                        {/* <CarbonAds
                            carbonUrl="//cdn.carbonads.com/carbon.js?serve=CESDT23M&placement=uiglass"
                            className="me-0"
                        /> */}
                        <script async type="text/javascript" src="https://cdn.carbonads.com/carbon.js?serve=CESDT23M&placement=uiglass" id="_carbonads_js"></script>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Hero
