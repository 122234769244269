import * as React from "react"
import Highlight, { defaultProps } from "prism-react-renderer";
import theme from "prism-react-renderer/themes/vsLight";
import ReactTooltip from 'react-tooltip';

class Code extends React.Component {

    getCSSCode() {
        switch (this.props.backgroundType) {
            case 'solid':
                var backgroundStyles = `background-color: ${this.props.getBackgroundStyles(this.props).backgroundColor};`;
                break;
            case 'gradient':
                var backgroundStyles = `background-color: ${this.props.colorBackgroundTertiary.hex};
    background-image: ${this.props.getBackgroundStyles(this.props).backgroundImage};`;
                break;
            case 'background_image':
                var backgroundStyles = `background-image: ${this.props.getBackgroundStyles(this.props).backgroundImage};
    background-position: center;`;
                break;
            // default:
            //     var backgroundStyles = `background-color: hsl(218, 39%, 11%);
            //     background-image: ${this.props.getBackgroundStyles(this.props.backgroundType, this.props.colorBackground, this.props.colorBackgroundSecondary, this.props.colorBackgroundTertiary, this.props.darkMode).backgroundImage};
            //     background-size: 74px 130px;
            //     background-position: 0 0,0 0,37px 65px,37px 65px,0 0,37px 65px;`;
        }

        if (this.props.darkMode) {
            var borderStyle = `border: 1px solid rgba(255, 255, 255, 0.125);`;
        } else {
            var borderStyle = `border: 1px solid rgba(209, 213, 219, 0.3);`;
        }

        return `/* Background styles */
body {
    ${backgroundStyles}
}

/* Glassmorphism card effect */
.card {
    backdrop-filter: blur(${this.props.blur}px) saturate(${this.props.saturation}%);
    -webkit-backdrop-filter: blur(${this.props.blur}px) saturate(${this.props.saturation}%);
    background-color: rgba(${this.props.color.rgb.r}, ${this.props.color.rgb.g}, ${this.props.color.rgb.b}, ${this.props.transparency / 100});
    border-radius: 12px;
    ${borderStyle}
}

/* Generated by https://generator.ui.glass/ */`;
    }

    getHTMLCode() {

        return `<li className="list-group-item bg-transparent border-bottom py-3 px-0">
    <div className="row align-items-center">
        <div className="col-auto">
            <a href="#" className="user-avatar rounded-circle bordered"><img className="p-1 rounded-circle" alt="Chris Wood" src={profile1} /></a>
        </div>
        <div className="col-auto px-0">
            <h3 className="fs-6 mb-0">Chris Wood</h3>
            <div className="d-flex align-items-center">
                <div className="dot bg-success rounded-circle me-2"></div>
                <span className="small text-muted">Online</span>
            </div>
        </div>
        <div className="col text-end">
            <a href="#" className="btn btn-sm btn-gray-600 border-gray-500 d-inline-flex align-items-center">
                <svg className="icon icon-xxs me-1" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-6 9l2 2 4-4"></path>
            </a>
</li>`;
    }

    render() {

        return (
            <div className="option-card shadow-lg rounded px-3 px-lg-4 py-4 code-card">
                <div className="row mb-4">
                    <div className="col-6">
                        <div className="switch-button">
                            <input className="switch-button-checkbox" id="codeLanguage" type="checkbox" defaultChecked={this.props.showHTML} onChange={this.props.handleCodeLanguageChange} />
                            <label className="switch-button-label" htmlFor="codeLanguage"><span className="switch-button-label-span">CSS</span></label>
                            <label className="switch-button-label-2" htmlFor="codeLanguage"><span className="switch-button-label-span">HTML</span></label>
                        </div>
                    </div>
                    <div className="col-6">
                        <ReactTooltip globalEventOff="click" place="top" type="dark" effect="solid" backgroundColor="#000" afterShow={() => { navigator.clipboard.writeText(this.getCSSCode()) }} />
                        <div className="icon-action d-flex align-items-center justify-content-center ms-auto"
                            data-tip="Copied!"
                            data-event="click"
                            data-event-off="dblclick">
                            <svg className="icon" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-3 7h3m-3 4h3m-6-4h.01M9 16h.01"></path></svg>
                        </div>
                    </div>
                </div>
                {this.props.showHTML ? (
                    <div className="html-coming-soon">
                        <div className="ui-glass-cta py-3 py-sm-4 px-3 px-sm-5 shadow-lg rounded text-center">
                            <span className="mb-3 d-block">The components are based on the upcoming <a href="https://ui.glass" target="_blank" className="hover-text-underline">ui.glass</a> UI library and you will be able to use the components by including the project via NPM.</span>
                            <a href="https://ui.glass" target="_blank" className="btn btn-sm btn-primary d-inline-flex align-items-center">Learn more <svg className="icon icon-xxs ms-1" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14"></path></svg></a>
                        </div>
                        <Highlight {...defaultProps} theme={theme} code={this.props.showHTML ? this.getHTMLCode() : this.getCSSCode()} language={this.props.showHTML ? 'html' : 'css'}>
                            {({ className, style, tokens, getLineProps, getTokenProps }) => (
                                <pre className={`prism-code language-${this.props.showHTML ? 'html' : 'css'} overflow-x-hidden`} style={style}>
                                    {tokens.map((line, i) => (
                                        <div {...getLineProps({ line, key: i })}>
                                            {line.map((token, key) => (
                                                <span {...getTokenProps({ token, key })} />
                                            ))}
                                        </div>
                                    ))}
                                </pre>
                            )}
                        </Highlight>
                    </div>
                ) : (
                    <Highlight {...defaultProps} theme={theme} code={this.props.showHTML ? this.getHTMLCode() : this.getCSSCode()} language={this.props.showHTML ? 'html' : 'css'}>
                        {({ className, style, tokens, getLineProps, getTokenProps }) => (
                            <pre className={`prism-code language-${this.props.showHTML ? 'html' : 'css'} overflow-x-hidden`} style={style}>
                                {tokens.map((line, i) => (
                                    <div {...getLineProps({ line, key: i })}>
                                        {line.map((token, key) => (
                                            <span {...getTokenProps({ token, key })} />
                                        ))}
                                    </div>
                                ))}
                            </pre>
                        )}
                    </Highlight>
                )}
            </div>
        );
    }
}
export default Code
